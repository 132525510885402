import React, { useState } from "react";
import BottomCTA from "../components/BottomCta";
import { NavigationBarVariant } from "../components/NavigationBar/models";
import NavigationBarSpacer from "../components/NavigationBarSpacer";
import PricingCard, { PricingCardVariant } from "../components/PricingCard";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import Section from "../containers/Section";
import { useSegment, trackView } from "../utils/analytics";
import { nav } from "../utils/navigation";
import { Name, Type, Location, Category, CTA_ID } from "../utils/analytics/constants";
import Header, { HeaderVariant } from "../components/Header";
import { graphql, useStaticQuery } from "gatsby";
import UIPill from "../components/UIPill";
import cn from "classnames";
import { useI18next } from "gatsby-plugin-react-i18next";
import { PAGE } from "../constants/pages";

interface PricingPageProps {
  data: any;
}

const PricingPage: React.FC<PricingPageProps> = ({ data }) => {
  const { helpers, segment } = useSegment();
  const { language } = useI18next();

  const [pricingOption, setPricingOption] = useState<{
    title: string;
    text: string;
    selected: boolean;
  }>(null);

  const pricingData = data?.pricingPage?.nodes[0];

  const pricingOptionsJP = [
    {
      title: "Starter",
      text: "ベーシックプラン",
      selected: false
    },
    {
      title: "Premium",
      text: "プレミアプラン",
      selected: false
    }
  ];

  if (pricingOption === null) {
    setPricingOption(pricingOptionsJP[0]);
  }

  return (
    <Layout
      navigationBarVariant={NavigationBarVariant.default}
      primaryButtonId={CTA_ID.getStartedNav.id}
    >
      <SEO
        title={pricingData?.seoSettings?.metadataTitle}
        description={pricingData?.seoSettings?.metadataDescription}
        robots={pricingData?.seoSettings?.robots}
      />
      <div className="bg-gray-5 pb-24">
        <div className="pt-20">
          <NavigationBarSpacer />
          <Section className="-mt-8 sm:mt-0">
            <div className="text-4xl sm:text-6xl font-bold text-center text-gray-3-dark justify-self-center">
              <Header variant={HeaderVariant.h1}>
                {pricingData?.bannerTitle}
              </Header>
            </div>
            {pricingData?.subtitle && (
              <p className="mx-auto text-center text-2xl font-semibold">
                {pricingData?.subtitle}
              </p>
            )}
            {language === "ja" && (
              <div className="mx-auto text-center mt-12">
                {pricingOptionsJP?.map((option, index) => {
                  return (
                    <UIPill
                      title={option?.text}
                      touching
                      className={cn(
                        "bg-gray-5 !text-gray-2-light",
                        index == 0
                          ? "rounded-l-lg rounded-r-none md:mx-0"
                          : index == 1
                          ? "rounded-r-lg md:mx-0 rounded-l-none"
                          : ""
                      )}
                      onClick={() => {
                        if (pricingOption !== option) {
                          setPricingOption(option);
                        }
                      }}
                      selected={pricingOption?.title === option?.title}
                    />
                  );
                })}
              </div>
            )}
          </Section>
        </div>
        <Section className="mt-20 lg:mt-32">
          <div
            className={cn(
              "sm:pb-8 pb-20 grid grid-cols-1 gap-5",
              language === "ja"
                ? "sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5"
                : "sm:grid-cols-2 xl:grid-cols-4"
            )}
          >
            {pricingData?.modules.map((item, index) => {
              if (item?.displayedTitle !== null) {
                return (
                  <PricingCard
                    page={PAGE.PRICING}
                    key={index}
                    variant={
                      language === "ja"
                        ? pricingOption?.title.toLowerCase()
                        : item.title.toLowerCase()
                    }
                    data={item}
                  />
                );
              }
            })}
          </div>
          <div className="flex-col items-center justify-center p-7 bg-gray-6 w-full mb-[100px] text-center">
            {language === "ja" ? (
              <div className="text-gray-2 text-base text-left">
                <p>
                  毎月の自動更新となります。適用プランは毎月の月額広告費に応じて自動で変動します。
                </p>
                <p>
                  プレミアプランのサポート内容の詳細はお問い合わせ時に説明いたします。
                </p>
                <p>プレミアプランは3ヶ月毎の契約となります。</p>
                <p>
                  プレミアプランにおいても弊社が直接管理画面の設定・変更を行うことは致しません。
                </p>
                <p>
                  現在はUSドルでのお支払いとなります。日本円決済に対応した際は別途消費税をお支払いいただきます。
                </p>
                <p>
                  原則クレジットカードでのお支払いとなります。銀行振込をご希望の場合はご相談ください。
                </p>
              </div>
            ) : (
              <p className="text-gray-2 text-base my-auto">
                {pricingData?.grayBanner?.grayBanner}
              </p>
            )}
          </div>
        </Section>

        <BottomCTA
          primaryButtonId={pricingData?.bottomCta?.primaryButtonId}
          secondaryButtonId={CTA_ID.demoBottom.id}
          title={pricingData?.bottomCta?.title}
          subtitle={<span>{pricingData?.bottomCta?.subtitle}</span>}
          primaryButtonText={pricingData?.bottomCta?.primaryButtonText}
          secondaryButtonText={pricingData?.bottomCta?.secondaryButtonText}
          primaryButtonClassName={
            pricingData?.bottomCta?.primaryButtonClassName
          }
          secondaryButtonClassName={
            pricingData?.bottomCta?.secondaryButtonClassName
          }
          primaryButtonOnClick={() =>
            helpers
              .delayTrack(Name.Cta, {
                category: Category.ButtonClick,
                type: Type.Button,
                text: pricingData?.bottomCta?.primaryButtonText,
                page_url: window?.location.href,
                destination: pricingData?.bottomCta?.primaryButtonRoute,
                location: Location.BottomCta
              })
              .then(() => {
                nav(pricingData?.bottomCta?.primaryButtonRoute);
              })
          }
          secondaryButtonOnClick={() => {
            trackView(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: pricingData?.bottomCta?.secondaryButtonText,
              page_url: window?.location.href,
              destination: pricingData?.bottomCta?.secondaryButtonRoute,
              location: Location.BottomCta
            });
            nav(pricingData?.bottomCta?.secondaryButtonRoute);
          }}
        />
      </div>
    </Layout>
  );
};

export default PricingPage;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pricingPage: allContentfulPricingPage(
      filter: { node_locale: { eq: $language } }
    ) {
      nodes {
        bannerTitle
        subtitle
        minimumBottomBanner
        modules {
          title
          displayedTitle
          price
          pricePerMonth
          taxes
          adSpent
          retailMediaPlatform
          cta {
            text
            route
          }
          pricingFeatures {
            text
            iconType
          }
        }
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            url
          }
          robots
        }
        bottomCta {
          title
          subtitle
          primaryButtonId
          secondaryButtonId
          primaryButtonText
          secondaryButtonText
          primaryButtonRoute
          secondaryButtonRoute
          primaryButtonClassName
          secondaryButtonClassName
        }
      }
    }
  }
`;
