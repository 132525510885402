import React from "react";
import classNames from "classnames";
export interface UIPillProps {
  title: string;
  selected?: boolean;
  className?: string;
  onClick?: () => void;
  touching?: boolean;
}

const UIPill: React.FC<UIPillProps> = props => {
  const { title, selected, className, onClick, touching = false } = props;

  return (
    <button
      className={classNames(
        className,
        "border-2 rounded-md mr-5 mb-2 font-semibold px-5 py-3 relative",
        { "md:mx-[-2px] first:ml-0 last:mr-0": touching },
        {
          "text-indigo-1 border-indigo-1 border-solid border-l-2 border-r-2 bg-indigo-3-light focus:outline-none z-10":
            selected,
          "text-gray-3 border-gray-3-light bg-white z-0": !selected
        }
      )}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default UIPill;
